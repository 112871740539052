<!-- 小组列表 -->
<template>
  <div class="dynamic-groupList">
    <div
      class="focusOn"
      :class="activeIndex === '1' ? 'active' : ''"
      @click="menuSelectEvent('1')"
    >
      <!-- 我关注的 -->
      {{ $t("label.h5.whatifollow") }}
    </div>
    <div
      class="involving"
      :class="activeIndex === '2' ? 'active' : ''"
      @click="menuSelectEvent('2')"
    >
      <!-- 涉及我的 -->
      {{ $t("vue_label_chatter_involving_me") }}
    </div>
    <div
      class="collection"
      :class="activeIndex === '3' ? 'active' : ''"
      @click="menuSelectEvent('3')"
    >
      <!-- 我收藏的 -->
      {{ $t("label.h5.bookmarked") }}
    </div>
    <div
      class="microPost"
      :class="activeIndex === '4' ? 'active' : ''"
      @click="menuSelectEvent('4')"
    >
      {{ $t("chatter.feed.menutype.feed.companycom") }}
      <!-- 所有微帖 -->
    </div>
    <div class="groupPart" :class="activeIndex === '5' ? 'active' : ''">
      <div
        @click="
          groupSelectShow();
          menuSelectEvent('5');
        "
      >
        {{ $t("label.chat.micropost.a.group") }}
      </div>
      <div
        class="groupAdd"
        @click="addGroupShow"
        v-if="
          activeIndex != '1' ||
          activeIndex != '2' ||
          activeIndex != '3' ||
          activeIndex != '4'
        "
      >
        <svg
          class="icon groupAddImg"
          aria-hidden="true"
          slot="reference"
          v-show="!changeBlue"
          @mouseover="overChange"
        >
          <use href="#icon-groupAdd"></use>
        </svg>
        <svg
          class="icon groupAddImg"
          aria-hidden="true"
          slot="reference"
          v-show="changeBlue"
          @mouseout="outChange"
        >
          <use href="#icon-blueB"></use>
        </svg>
      </div>
    </div>
    <!-- 点击"小组"出现下拉框 -->
    <div class="groupSelect" v-show="groupSelectTo">
      <!-- 小组下拉 -->
      <div class="groupSelectChange">
        <el-select v-model="value" @change="changeValue">
          <el-option
            v-for="item in groupOptions"
            :key="item.value"
            :label="item.label"
            :value="item.label"
          ></el-option>
        </el-select>
      </div>
      <!-- 小组详细列表 -->
      <div
        class="groupDetailedList"
        v-for="(item, index) in groupListRes"
        :key="index"
        @click="menuSelectEvent(item, index)"
        :class="
          typeof activeIndex === 'object' && activeArrIndex == index
            ? 'active'
            : ''
        "
      >
        <!-- 头像 -->
        <div>
          <img
            :src="`${imgUrlCookie}/chatterGroup.action?m=showGroupImage&id=${
              item._id
            }&binding=${tokenCookie}&dandan=${Math.random()}`"
            alt
          />
        </div>
        <!-- 小组名称 -->
        <div>
          <p>{{ item.name }}</p>
        </div>
        <!-- 当前登录用户与小组的关系 -->
        <div
          @click="isStatusOpen(item.isStatus, item.name, item._id, index)"
          :class="
            item.isStatus != $t('label.referperson')
              ? 'openStatus'
              : 'noOpenStatus'
          "
        >
          {{ item.isStatus }}
        </div>
      </div>
      <!-- 小组分页 -->
      <div class="groupPage" v-if="valG != $t('Dashboard_View_RecentlyView')">
        <el-button @click="previousPage()" :disabled="pageNum === 1">{{
          $t("label.page.previous")
        }}</el-button>
        <el-button @click="nextPage()" :disabled="pageNum > totalPage - 1">{{
          $t("label.page.next")
        }}</el-button>
      </div>
    </div>
    <!-- 新建小组弹框 -->
    <AddGroup
      :username="username"
      :addGroupTo="addGroupTo"
      @theFind="theFind"
      @addGroupCancel="addGroupCancel"
      @addGroupConfirm="addGroupConfirm"
    />
    <!-- 查找弹框 -->
    <TheFind
      :theFindTo="theFindTo"
      @theFindCancle="theFindCancle"
      @theFindConfirm="theFindConfirm"
    />
    <!-- 上传小组头像弹框 -->
    <UploadPicture
      :uploadPictureTo="uploadPictureTo"
      @uploadPictureCancel="uploadPictureCancel"
      @uploadPictureConfirm="uploadPictureConfirm"
      :newGroupId="newGroupId"
    />

    <!-- 成员管理 -->
    <members-manage
      @loadMore="loadMore"
      :newGroupId="newGroupId"
      @clearKeyWord="clearKeyWord"
      :queryUserResData="queryUserResData"
      :userListData="userListData"
      :createdUserId="createdUserId"
      :createdUserIdGroup="createdUserIdGroup"
      :membersManageTo="membersManageTo"
      @getNewPerson="getNewPerson"
      @membersManageCancel="membersManageCancel"
      @membersManageConfirm="membersManageConfirm"
    />
    <!-- 当前用户与小组的状态弹框 -->
    <ThisPeopleState
      :titleName="titleName"
      :isStatus="isStatus"
      :thisPeopleStateTo="thisPeopleStateTo"
      @thisPeopleStateCancel="thisPeopleStateCancel"
      @thisPeopleStateConfirm="thisPeopleStateConfirm"
    />
  </div>
</template>

<script>
//api
import {
  queryUserGroups, //获取CCChat小组列表
  joinGroup, //添加CCChat小组成员
  removeGroupMember, //删除CCChat小组成员(退出小组)
  addRecent, //添加CCChat小组最近查看
  addUserGroupInfo, //新增CCChat小组
  queryUser, //查询CCChat小组用户详细信息
  queryGroupManager, //查询CCChat小组成员
  uploadGroupImage, //上传小组头像
} from "../api.js";
//components
import AddGroup from "./dynamic-groupList/AddGroup"; //添加小组
import TheFind from "./dynamic-groupList/TheFind"; //查找
import UploadPicture from "./dynamic-groupList/UploadPicture"; //上传小组头像
import MembersManage from "./dynamic-groupList/MembersManage"; // 成员管理
import ThisPeopleState from "./dynamic-groupList/ThisPeopleState"; //添加小组
import VueCookies from "vue-cookies";
export default {
  components: {
    AddGroup,
    TheFind,
    UploadPicture,
    MembersManage,
    ThisPeopleState,
  },
  data() {
    return {
      changeBlue: false,
      formNameC: "",
      keyword: "", //搜索的关键词
      uploadObj: "",
      dataFile: "",
      tokenCookie: "", //token
      imgUrlCookie: "", //域名
      notificationGroupId: this.$route.query.id, //通知中心传过来的小组记录id
      category: this.$route.query.category, //通知中心传过来的消息类型
      userListData: [], //小组成员
      queryUserResData: [], //全部人员
      createdUserId: "", //当前登录用户ID -- 所有人员
      createdUserIdGroup: "", //当前登录用户ID -- 小组中
      pageUserNum: "1",
      pageUserSize: "10",
      newGroupId: "", //新增小组之后得到的ID
      username: "", //当前登录的用户名
      isStatus: "", //状态
      titleName: "", //弹框名字
      groupId: "", //小组id
      thisPeopleStateTo: false,
      addGroupTo: false, //新增小组的弹框
      theFindTo: false, //查找弹框
      uploadPictureTo: false, //上传小组头像的弹框
      membersManageTo: false, //成员管理的弹框
      groupSelectTo: true, //小组的详细列表默认不展示
      groupListRes: [], //所有小组列表数据
      userId: "", //当前用户的ID
      groupTypeValue: "", //当前小组的类型(公用还是私用)
      groupType: "ug_myGroup", //默认显示我的小组
      pageNum: 1, //当前为第几页f
      pageSize: 5, //默认5条一页
      totalPage: 1, //共几页
      activeIndex:
        this.$route.query.id && this.$route.query.category == "showmsg"
          ? ""
          : "1", //当前选中帖子类型
      activeArrIndex: 0, //选择的当小组的下标
      value: this.$i18n.t("label_chatter_mygroups"), //我的小组
      valG: "",
      groupOptions: [
        {
          value: "1",
          label: this.$i18n.t("label_chatter_mygroups"), //我的小组
        },
        {
          value: "2",
          label: this.$i18n.t("vue_label_chatter_all_groups"), //"所有小组"
        },
        {
          value: "3",
          label: this.$i18n.t("Dashboard_View_RecentlyView"), //最近查看
        },
      ],
    };
  },
  watch: {
    //在同事圈主页时打开通知中心跳转到同事圈小组
    $route: function (to) {
      //监听到路由（参数）改变
      // 拿到目标参数 to.query.category 去再次请求数据接口
      this.notificationGroupId = to.query.id;
      this.category = to.query.category;
      if (this.notificationGroupId && this.category == "ChatterGroup") {
        let groupItem = {}; // 小组数据object
        let groupIndex = -1; // 小组所在列表的index
        //根据传过来的小组id匹配对应小组
        this.groupListRes.find((item, index) => {
          if (item._id === this.notificationGroupId) {
            groupItem = item;
            groupIndex = index;
          }
        });
        //若匹配不到小组，则说明该小组被删除
        if (groupIndex === -1) {
          this.$message.info(
            this.$i18n.t("vue_label_ccchat_group_deletedgroup") //该小组已被删除
          );
          return;
        }
        this.groupSelectTo = true; // 控制展开小组列表
        this.menuSelectEvent(groupItem, groupIndex); // 执行选中小组操作
      }
    },
  },
  async mounted() {
    this.tokenCookie = this.$CCDK.CCToken.getToken(); //token
    this.imgUrlCookie = VueCookies.get("domainName"); //获取域名
    await this.queryUserGroups(this.pageNum, this.pageSize); //获取小组列表
    //不在同事圈主页(即在别的主页时)打开通知中心跳转到同事圈小组
    if (this.notificationGroupId && this.category == "ChatterGroup") {
      let groupItem = {}; // 小组数据object
      let groupIndex = -1; // 小组所在列表的index
      this.groupListRes.find((item, index) => {
        if (item._id === this.notificationGroupId) {
          groupItem = item;
          groupIndex = index;
        }
      });
      if (groupIndex === -1) {
        this.$message.info(this.$i18n.t("vue_label_ccchat_group_deletedgroup")); //该小组已被删除
        return;
      }
      this.groupSelectTo = true; // 控制展开小组列表
      this.menuSelectEvent(groupItem, groupIndex); //执行选中小组操作
    }
    this.$bus.$on("getGroupList", this.ebFn1);
    this.$bus.$on("getGroupMembers", this.ebFn2);
  },
  destroyed() {
    this.$bus.$off("getGroupList",this.ebFn1);
    this.$bus.$off("getGroupMembers",this.ebFn2);
  },
  methods: {
    ebFn1(){
      this.queryUserGroups(this.pageNum, this.pageSize);
    },
    ebFn2(){
      this.uploadPictureConfirm(this.uploadObj, this.dataFile);
    },
    overChange() {
      this.changeBlue = true;
    },
    outChange() {
      this.changeBlue = false;
    },

    // 退出小组(删除小组成员)
    async removeGroupMember() {
      await removeGroupMember({
        groupId: this.groupId,
        userId: this.userId,
      });
      this.$message.success(
        this.$i18n.t("vue_label_chatter_success_exit_group")
      ); //"退出小组成功"
    },
    //添加CCChat小组成员(申请加入--先加入到审批中)
    async addGroupIsOne() {
      await joinGroup({
        groupId: this.groupId,
        userId: this.userId,
        joinType: "apply",
      });
    },
    //取消申请
    async reqaddGroup() {
      await joinGroup({
        groupId: this.groupId,
        userId: this.userId,
        joinType: "rejected",
      });
    },
    //申请加入--取消申请--退出小组--加入小组
    isStatusOpen(isStatus, name, groupId) {
      if (isStatus != this.$i18n.t("label.referperson")) {
        this.thisPeopleStateTo = true;
        this.isStatus = isStatus;
        this.titleName = name;
        this.groupId = groupId;
      }
    },
    // 当前用户与小组的状态弹框--确定
    async thisPeopleStateConfirm() {
      this.thisPeopleStateTo = false;
      switch (this.isStatus) {
        case this.$i18n.t("chatter.group.applyToJoin"):
          await this.addGroupIsOne();
          break;
        case this.$i18n.t("chatter.group.cancelRequest"):
          await this.reqaddGroup();
          break;
        case this.$i18n.t("label_tabpage_exitz"):
          await this.removeGroupMember();
          break;
      }
      await this.queryUserGroups(this.pageNum, this.pageSize); // 获取小组列表
      if (
        this.isStatus === this.$i18n.t("label_tabpage_exitz") ||
        this.isStatus === this.$i18n.t("label.referperson")
      ) {
        await this.$bus.$emit("getGroupUser", this.activeIndex);
      }
    },
    // 当前用户与小组的状态弹框--取消
    thisPeopleStateCancel() {
      this.thisPeopleStateTo = false;
    },
    //点击小组出现小组的详细列表
    groupSelectShow() {
      this.groupSelectTo = !this.groupSelectTo;
    },
    changeValue(val) {
      //所有小组--我的小组--最近查看
      this.valG = val;
      switch (val) {
        case this.$i18n.t("label_chatter_mygroups"):
          this.groupType = "ug_myGroup";
          break;
        case this.$i18n.t("vue_label_chatter_all_groups"):
          this.groupType = "all";
          break;
        case this.$i18n.t("Dashboard_View_RecentlyView"):
          this.groupType = "recent";
          break;
      }
      this.pageNum = 1;
      this.queryUserGroups(this.pageNum, this.pageSize);
    },
    //获取所有小组列表
    async queryUserGroups(pageNum, pageSize) {
      let groupListRes = await queryUserGroups({
        keyword: "",
        groupType: this.groupType,
        pageNum: pageNum,
        pageSize: pageSize,
      });
      this.groupListRes = groupListRes.data.list;
      this.totalPage = groupListRes.data.totalPage;
      this.userId = groupListRes.data.userId;
      //isStatus--给每个小组添加一个标识代表 当前用户与该小组的关系
      groupListRes.data.list.map((item) => {
        if (item.ownerId === this.userId) {
          //所有人
          item.isStatus = this.$i18n.t("label.referperson");
        }
        // 如果该小组的groupTypeValue为公用
        else if (item.groupType === "public") {
          item.groupusers.filter((item1) => {
            //如果成员id与用户ID相等
            if (item1.userid === this.userId) {
              item.isStatus = this.$i18n.t("label_tabpage_exitz");
            } else {
              //申请加入
              item.isStatus = this.$i18n.t("chatter.group.applyToJoin");
            }
          });
          item.applyUser.filter((item2) => {
            //如果申请成员id与用户ID相等
            //取消申请
            if (item2.userid == this.userId && item2.userstatus === "apply") {
              item.isStatus = this.$i18n.t("chatter.group.cancelRequest");
            }
          });
        }
        // 如果该小组的groupTypeValue不为公用,(也就是私用)
        else if (item.groupType === "private") {
          item.groupusers.filter((item3) => {
            //如果成员id与用户ID相等
            if (item3.userid === this.userId) {
              // isStatus--给每个小组添加一个标识代表 当前用户与该小组的关系
              item.isStatus = this.$i18n.t("label_tabpage_exitz");
            }
          });
        }
      });
      this.$forceUpdate();
    },
    //上一页
    previousPage() {
      if (this.pageNum > 1) {
        this.pageNum = this.pageNum - 1;
        this.queryUserGroups(this.pageNum, this.pageSize);
      }
    },
    //下一页
    nextPage() {
      if (this.pageNum < this.totalPage) {
        this.pageNum = this.pageNum + 1;
        this.queryUserGroups(this.pageNum, this.pageSize);
      }
    },
    // 菜单选择事件，选择：关注、涉及、收藏、所有、小组标题、具体小组
    async menuSelectEvent(menuName, index) {
      //传给父组件main.vue，获取选中的菜单，且在其他组件中监听数据获取小组详情
      this.$emit("menuSelectEvent", menuName);
      //添加CCChat小组最近查看
      if (typeof menuName == "object") {
        // 选择的是具体小组，menuName为小组数据
        this.activeArrIndex = index;
        //最近查看
        await addRecent({
          groupId: menuName._id, // || this.notificationGroupId
        });
        // }
        this.queryUserGroups(this.pageNum, this.pageSize);
        //传给组件dynamic-applyFor.vue，查询小组成员
        if (
          menuName.isStatus === this.$i18n.t("label_tabpage_exitz") ||
          menuName.isStatus === this.$i18n.t("label.referperson")
        ) {
          this.$bus.$emit("getGroupUser", menuName);
        }
      }
      this.activeIndex = menuName;
    },
    //点击"+"出现新建小组弹框
    addGroupShow() {
      this.addGroupTo = true;
      this.username = localStorage.getItem("username");
    },
    //取消新建小组
    addGroupCancel(formName) {
      this.addGroupTo = false;
      this.formNameC = formName;
      this.formNameC.groupName = "";
      this.formNameC.desc = "";
      this.formNameC.resource = "";
    },
    //新建小组弹框中的查找
    theFind() {
      this.theFindTo = false;
    },
    //取消查找
    theFindCancle() {
      this.theFindTo = false;
    },
    //查找-->保存并继续
    theFindConfirm() {
      this.theFindTo = false;
    },
    //新建小组-->保存并继续后-->打开选择小组图片弹框
    async addGroupConfirm(formName) {
      this.formName = formName;
      this.addGroupTo = false;
      let addUserGroupInfoRrs = await addUserGroupInfo({
        name: formName.groupName,
        description: formName.desc,
        groupType: formName.resource,
      });
      this.newGroupId = addUserGroupInfoRrs.data.id;
      this.uploadPictureTo = true;
      this.formName.groupName = "";
      this.formName.desc = "";
      this.formName.resource = "";
    },
    // 取消选择小组图片
    uploadPictureCancel() {
      this.uploadPictureTo = false;
      this.formName.groupName = "";
      this.formName.desc = "";
      this.formName.resource = "";
      this.queryUserGroups(this.pageNum, this.pageSize);
    },
    //搜索
    getNewPerson(newPersonKeyword) {
      this.keyword = newPersonKeyword;
      this.uploadPictureConfirm(this.uploadObj, this.dataFile);
    },
    //清空关键字
    clearKeyWord() {
      this.keyword = "";
    },
    // 选择小组图片-->下一步-->打开选择成员管理弹框
    async uploadPictureConfirm(uploadObj, dataFile) {
      this.uploadPictureTo = false;
      this.uploadObj = uploadObj;
      this.dataFile = dataFile;
      let formData = new FormData();
      // 未上传图片时不调用上传头像接口
      if (JSON.stringify(dataFile) !== "{}") {
        formData.append("file", dataFile);
        formData.append("contJson", JSON.stringify(uploadObj));
        //上传小组头像
        await uploadGroupImage(formData);
      }
      //查询CCChat小组用户详细信息
      let queryUserRes = await queryUser({
        groupId: this.newGroupId,
        keyword: this.keyword,
        pageNum: this.pageUserNum,
        pageSize: this.pageUserSize,
      });
      this.queryUserResData = queryUserRes.data.user_l;
      this.createdUserId = queryUserRes.data.userId;
      //查询CCChat小组成员
      let queryGroupManagerRes = await queryGroupManager({
        groupId: this.newGroupId,
        mark: "all",
        keyword: this.keyword,
        pageNum: this.pageUserNum,
        pageSize: this.pageUserSize,
      });
      this.userListData = queryGroupManagerRes.data.userList;
      this.createdUserIdGroup = queryGroupManagerRes.data.userId;
      this.membersManageTo = true;
      this.formName.groupName = "";
      this.formName.desc = "";
      this.formName.resource = "";
    },
    //滚动加载
    loadMore() {
      this.pageUserSize = Number(this.pageUserSize) + 5;
      this.uploadPictureConfirm(this.uploadObj, this.dataFile);
    },
    //取消成员管理弹框
    membersManageCancel() {
      this.membersManageTo = false;
      this.formName.groupName = "";
      this.formName.desc = "";
      this.formName.resource = "";
    },
    //完成成员管理弹框
    membersManageConfirm() {
      this.membersManageTo = false;
      this.queryUserGroups(this.pageNum, this.pageSize);
      this.formName.groupName = "";
      this.formName.desc = "";
      this.formName.resource = "";
    },
  },
};
</script>

<style lang="scss" scoped>
p {
  padding: 0;
  margin: 0;
}
.dynamic-groupList {
  padding-top: 10px;
  //所有标题
  .focusOn,
  .involving,
  .collection,
  .microPost,
  .groupPart {
    padding: 0px 20px;
    width: 100%;
    text-align: left;
    font-size: 14px;
    color: #080707;
    height: 30px;
    line-height: 30px;
    &:hover {
      background: #dce9ff;
      cursor: pointer;
    }
  }
  .active {
    background: #dce9ff;
    cursor: pointer;
  }
  .openStatus:hover {
    color: #006dcc;
    cursor: pointer;
  }
  .noOpenStatus {
    cursor: default;
  }
  //小组
  .groupPart {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 16px;
    div:first-of-type {
      width: 60%;
    }
    .groupAdd {
      // display: flex;
      // align-items: center;
      text-align: right;
      .groupAddImg {
        width: 14px;
        height: 14px;
        &:hover {
          // cursor: pointer;
          // border: 1px solid rgb(134, 134, 134);
        }
      }
      // p {
      //   width: 36px;
      //   overflow: hidden;
      //   text-overflow: ellipsis;
      //   white-space: nowrap;
      // }
    }
  }
  .groupSelect {
    background: white;
    // 小组下拉选择
    .groupSelectChange {
      padding: 0px 82px 0px 34px;
    }
    .el-select {
      margin-top: 8px;
      width: 104px;
      height: 24px;
    }
    ::v-deep .el-input {
      height: 24px;
    }
    ::v-deep .el-input__inner {
      height: 24px;
      line-height: 24px;
      font-size: 12px;
      color: #080707;
    }
    ::v-deep .el-input__suffix-inner {
      line-height: 24px !important;
      height: 24px !important;
    }
    ::v-deep .el-select__caret {
      line-height: 24px !important;
      height: 24px !important;
    }

    // 小组详细列表
    .groupDetailedList {
      padding: 0px 20px 0px 34px;
      &:hover {
        background: #dce9ff;
        cursor: pointer;
      }
      margin-top: 10px;
      height: 34px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 12px;
      display: flex;
      color: #080707;
      div:first-of-type {
        width: 26px;
        img {
          width: 26px;
          height: 28px;
        }
      }
      div:nth-of-type(2) {
        margin: 0 6px;
        width: calc(100% - 86px);
        p {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }

      div:last-of-type {
        width: 48px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
    //小组的分页
    .groupPage {
      display: flex;
      justify-content: space-around;
      // position: fixed;
      // bottom: 12px;
      font-size: 12px;
      color: #acacac;
      padding-left: 28px;
      padding-right: 18px;
      margin-left: 0;
      .el-button {
        border: none;
        // background: none;
        // &:hover {
        //   background: none;
        // }
      }
    }
  }
}
</style>
