<!--
 * @Author: your name
 * @Date: 2021-12-03 14:36:43
 * @LastEditTime: 2022-06-24 13:57:36
 * @LastEditors: 张亚欣 18911148524@163.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \lightning-web\src\views\colleagueCircle\components\dynamic-applyFor\DelGroupPeople.vue
-->
<!--
  删除小组成员弹框
-->
<template>
  <el-dialog
    :visible.sync="delGroupPeople"
    :title="$t('vue_label_chatter_remove_member')"
    :before-close="handleClose"
    :close-on-click-modal="false"
    append-to-body
  >
    <div class="dialog-body">
      <div>
        <!-- <p>确定要移除小组成员"{{name}}"吗?</p> -->
        <p>{{ $t("vue_label_chatter_removecertification", { name: name }) }}</p>
        <!-- 此项操作不可撤回 -->
        <p>{{ $t("vue_label_norm_undone") }}</p>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="delGroupPeopleCancel">{{
        $t("button_partner_cancel")
      }}</el-button>
      <el-button type="primary" @click="delGroupPeopleConfirm">{{
        $t("label.ems.confirm")
      }}</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  props: {
    delGroupPeopleTo: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      delGroupPeople: false,
    };
  },
  watch: {
    delGroupPeopleTo: {
      handler: function (newVal) {
        this.delGroupPeople = newVal;
      },
      immediate: true,
    },
  },
  methods: {
    handleClose() {
      this.$emit("delGroupPeopleCancel");
    },
    delGroupPeopleCancel() {
      this.$emit("delGroupPeopleCancel");
    },
    delGroupPeopleConfirm() {
      this.$emit("delGroupPeopleConfirm");
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-dialog {
  width: 552px;
  // height: 390px;
}
.dialog-body {
  div {
    p:first-child {
      font-size: 16px;
      color: #686868;
    }
    p:last-child {
      color: #ff3c32;
      font-size: 12px;
    }
  }
}
</style>
