<!-- 查找 -->
<template>
  <el-dialog
    style="text-align: left"
    title="查找"
    :visible.sync="theFind"
    :before-close="handleClose"
    :close-on-click-modal="false"
    :append-to-body="true"
    width="860px"
  >
    <div class="select-box">
      <div class="select-name">
        <div>名称</div>
        <el-input
          v-model="groupName"
          placeholder="请输入小组名称进行查找"
        ></el-input>
      </div>
      <div class="select-phone">
        <div>手机</div>
        <el-input
          v-model="groupPhone"
          placeholder="请输入手机号进行查找"
        ></el-input>
      </div>
    </div>
    <div class="select-btn">
      <el-button size="mini" @click="groupSearch">搜索</el-button>
      <el-button size="mini" @click="groupClear">清空</el-button>
    </div>
    <el-table
      :data="tableData"
      height="250"
      border
      style="width: 100%; overflow-y: auto"
    >
      <el-table-column prop="nameG" label="名称" width="180"> </el-table-column>
      <el-table-column prop="tel" label="手机" width="180"> </el-table-column>
      <el-table-column prop="mail" label="电子邮件"> </el-table-column>
    </el-table>

    <span slot="footer" class="dialog-footer">
      <el-button size="mini" @click="theFindCancle">取消</el-button>
      <el-button
        type="primary"
        size="mini"
        @click="theFindConfirm"
        style="background: #1b5297"
        >保存并继续</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
export default {
  props: {
    theFindTo: {
      //是否显示编辑弹框
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      theFind: false,
      groupName: "", //小组名称
      groupPhone: "", //小组手机号
      tableData: [
        {
          nameG: "lllll",
          tel: "19939964650",
          mail: "pengyd@cloudcc.com",
        },
        {
          nameG: "lllll",
          tel: "19939964650",
          mail: "pengyd@cloudcc.com",
        },
        {
          nameG: "lllll",
          tel: "19939964650",
          mail: "pengyd@cloudcc.com",
        },
        {
          nameG: "lllll",
          tel: "19939964650",
          mail: "pengyd@cloudcc.com",
        },
        {
          nameG: "lllll",
          tel: "19939964650",
          mail: "pengyd@cloudcc.com",
        },
        {
          nameG: "lllll",
          tel: "19939964650",
          mail: "pengyd@cloudcc.com",
        },
        {
          nameG: "lllll",
          tel: "19939964650",
          mail: "pengyd@cloudcc.com",
        },
        {
          nameG: "lllll",
          tel: "19939964650",
          mail: "pengyd@cloudcc.com",
        },
        {
          nameG: "lllll",
          tel: "19939964650",
          mail: "pengyd@cloudcc.com",
        },
        {
          nameG: "lllll",
          tel: "19939964650",
          mail: "pengyd@cloudcc.com",
        },
        {
          nameG: "lllll",
          tel: "19939964650",
          mail: "pengyd@cloudcc.com",
        },
        {
          nameG: "lllll",
          tel: "19939964650",
          mail: "pengyd@cloudcc.com",
        },
      ],
    };
  },
  watch: {
    theFindTo: {
      handler: function (newVal) {
        this.theFind = newVal;
      },
      immediate: true,
    },
  },
  methods: {
    //取消
    theFindCancle() {
      this.$emit("theFindCancle");
    },
    handleClose() {
      this.$emit("theFindCancle");
    },
    //保存并继续
    theFindConfirm() {
      this.$emit("theFindConfirm");
    },
    //搜索
    groupSearch() {},
    //清空
    groupClear() {
      this.groupName = "";
      this.groupPhone = "";
    },
  },
};
</script>

<style lang='scss' scoped>
.select-box {
  display: flex;
  .select-name {
    margin-right: 100px;
  }
  .select-name,
  .select-phone {
    display: flex;
    vertical-align: middle;
    justify-content: center;
    align-items: center;
    div:first-child {
      margin-right: 34px;
    }
    ::v-deep .el-input,
    ::v-deep .el-input__inner {
      width: 280px;
      height: 28px;
    }
  }
}
.select-btn {
  margin: 30px 0 14px 0;
  .el-button {
    height: 30px;
    width: 57px;
    font-size: 12px;
    padding: 0;
    background: #006dcc;
    border-radius: 3px;
    color: #fff;
  }
  .el-button + .el-button {
    margin-left: 20px;
  }
}
</style>