<!-- 小组详细信息 -->
<template>
  <div class="dynamic-message">
    <div class="group_message">
      <!-- 头像 -->
      <div class="message_img">
        <img :src="groupInfoList.image" alt />
      </div>
      <!-- 小相机 -->
      <div
        class="xiangji"
        @click="editGroupImg"
        v-show="groupInfoList.loginUserGroupRole === 'manager'"
      >
        <svg class="icon xiangjiImg" aria-hidden="true" slot="reference">
          <use href="#icon-xaingji"></use>
        </svg>
      </div>
      <!-- 详细信息 -->
      <div class="message_info">
        <div
          class="info_title"
          @click="jumpGroupDetails"
          :class="
            isStatus == $t('label.referperson') ||
            isStatus == $t('label_tabpage_exitz')
              ? 'jumpGroupDetailsClass'
              : ''
          "
        >
          {{ groupInfoList.name }}
        </div>
        <div class="info_edit">
          <!-- 所有人 -->
          <div class="groupName">
            <div>{{ $t("label.referperson") }}</div>
            <div @click="jumpDetails(groupInfoList.ownerId)">
              {{ groupInfoList.ownerName }}
            </div>
          </div>
          <!-- 访问类型 -->
          <div class="group_type">
            <div>{{ $t("label.weixin.group.type") }}</div>
            <div>
              <svg
                class="icon suoImg"
                aria-hidden="true"
                slot="reference"
                v-show="groupInfoList.groupType == 'private'"
              >
                <use href="#icon-suo"></use>
              </svg>
            </div>
            <div>{{ groupInfoList.groupTypeValue }}</div>
            <div v-show="groupInfoList.loginUserGroupRole === 'manager'">
              <svg
                class="icon editNameImg"
                aria-hidden="true"
                slot="reference"
                v-popover:popoverType
              >
                <use href="#icon-editName"></use>
              </svg>
            </div>
            <!-- 访问类型气泡 -->
            <el-popover
              placement="bottom"
              trigger="click"
              width="246"
              popper-class="popo_operation"
              ref="popoverType"
            >
              <div class="name-box">
                <!-- 访问类型 -->
                <div class="editType">{{ $t("label.weixin.group.type") }}</div>
                <el-form
                  :model="typeForm"
                  :rules="rules"
                  ref="typeForm"
                  label-width="100px"
                  class="demo-ruleForm"
                >
                  <el-form-item
                    :label="$t('label.weixin.group.type')"
                    prop="editAccessType"
                  >
                    <el-select
                      v-model="typeForm.editAccessType"
                      :placeholder="$t('label.weixin.group.type')"
                    >
                      <el-option
                        :label="$t('label.customsetting.visible.public')"
                        value="public"
                      ></el-option>
                      <el-option
                        :label="$t('label.useonly')"
                        value="private"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </el-form>
                <div class="butts">
                  <el-button @click.native="editAccessTypeCancel">{{
                    $t("button_partner_cancel")
                  }}</el-button>
                  <el-button
                    type="primary"
                    @click.native="editAccessTypeSave('typeForm')"
                    >{{ $t("label.save") }}</el-button
                  >
                </div>
              </div>
            </el-popover>
          </div>
        </div>
        <!-- 备注 -->
        <div class="info_note">
          <div>{{ $t("label.file.des") }}</div>
          <div>{{ groupInfoList.description }}</div>
          <div v-show="groupInfoList.loginUserGroupRole === 'manager'">
            <svg
              class="icon editNameImg"
              aria-hidden="true"
              slot="reference"
              v-popover:popoverDescription
            >
              <use href="#icon-editName"></use>
            </svg>
          </div>
          <!-- 备注气泡 -->
          <el-popover
            placement="bottom"
            trigger="click"
            width="246"
            popper-class="popo_operation"
            ref="popoverDescription"
          >
            <div class="name-box">
              <!-- 备注 -->
              <div class="editDescription">{{ $t("label.file.des") }}</div>
              <el-form
                :model="descriptionForm"
                :rules="rules"
                ref="descriptionForm"
                label-width="100px"
                class="descriptionruleForm"
              >
                <el-form-item
                  prop="editDescription"
                  class="descriptionruleFormItem"
                >
                  <!-- :label="$t('label.file.des')" -->
                  <el-input
                    type="textarea"
                    v-model="descriptionForm.editDescription"
                  ></el-input>
                </el-form-item>
              </el-form>
              <div class="butts">
                <el-button @click.native="editDescriptionCancel">{{
                  $t("button_partner_cancel")
                }}</el-button>
                <el-button type="primary" @click.native="editDescriptionSave">{{
                  $t("label.save")
                }}</el-button>
              </div>
            </div>
          </el-popover>
        </div>
      </div>
    </div>
    <!-- 删除小组 -->
    <div
      class="group_delete"
      @click="delThisGroup"
      v-if="userId === groupInfoList.ownerId"
    >
      <!-- 删除小组 -->
      {{ $t("button_partner_delete") }}
    </div>
    <!-- 注释:只有小组的所有人才有权限删除小组(才能有'删除小组') -->
    <!-- 删除小组弹框 -->
    <DelThisGroup
      :groupName="groupName"
      :delThisGroupTo="delThisGroupTo"
      @delThisGroupConfirm="delThisGroupConfirm"
      @delThisGroupCancel="delThisGroupCancel"
    />
    <!-- 修改小组头像弹框 -->
    <EditPicture
      :editPictureTo="editPictureTo"
      :oldImg="oldImg"
      :groupId="groupId"
      @editGroupImgCancel="editGroupImgCancel"
      @editGroupImgConfirm="editGroupImgConfirm"
    />
  </div>
</template>

<script>
import {
  queryUserGroupInfo, //获取CCChat小组详细信息
  updateUserGroupInfo, //更新CCChat小组
  uploadGroupImage, //上传小组头像
} from "../api.js";
import EditPicture from "./dynamic-message/EditPicture";
import DelThisGroup from "./dynamic-message/DelThisGroup";
import VueCookies from "vue-cookies";
export default {
  components: {
    DelThisGroup,
    EditPicture,
  },
  props: {
    //从main.vue中传过来的对象,取每个_id来调取获取CCChat小组详细信息的接口
    menuSelectStatus: [Object,String],
    isStatus: {
      type: String,
      default: "",
    },
  },
  watch: {
    //监听
    menuSelectStatus() {
      if (typeof this.menuSelectStatus == "object") {
        this.queryUserGroupInfo(this.menuSelectStatus);
      }
    },
  },
  data() {
    return {
      groupName: "", //小组名称
      oldImg: "",
      groupInfoList: {}, //小组的详细信息
      delThisGroupTo: false,
      editPictureTo: false,
      userId: "", //当前用户id
      groupId: "", //小组id
      ownerId: "", //所有人id
      typeForm: {
        editAccessType: "",
      },
      descriptionForm: {
        editDescription: "",
      },
      rules: {
        editAccessType: [
          {
            required: true,
            message: this.$i18n.t("label.weixin.group.type"),
            trigger: "change",
          },
        ],
      },
    };
  },
  mounted() {
    this.$bus.$on("getGroupListInfo", this.ebFn);
  },
  destroyed() {
    this.$bus.$off("getGroupListInfo",this.ebFn);
  },
  methods: {
    ebFn(){
      queryUserGroupInfo(this.menuSelectStatus);
    },
    // 点击跳转到小组详情
    jumpGroupDetails() {
      if (
        this.isStatus == this.$i18n.t("label.referperson") ||
        this.isStatus == this.$i18n.t("label_tabpage_exitz")
      )
        this.$router.push({
          path: `/groupObject/groupDetails`,
          query: {
            id: this.groupId,
            isStatus: this.isStatus,
          },
        });
    },
    //修改小组头像
    editGroupImg() {
      this.editPictureTo = true;
    },
    editGroupImgCancel() {
      this.editPictureTo = false;
    },
    async editGroupImgConfirm(uploadObj, dataFile) {
      let formData = new FormData();
      // 未上传图片时不调用上传头像接口
      if (JSON.stringify(dataFile) !== "{}") {
        formData.append("file", dataFile);
        formData.append("contJson", JSON.stringify(uploadObj));
        //上传小组头像
        await uploadGroupImage(formData);
      }
      this.groupInfoList.image = `${VueCookies.get(
        "domainName"
      )}/chatterGroup.action?m=showGroupImage&id=${
        this.menuSelectStatus._id
      }&binding=${this.$CCDK.CCToken.getToken()}&dandan=${Math.random()}`;
      this.editPictureTo = false;
      this.$bus.$emit("getGroupList");
    },
    //点击所有人跳转到对应的详情（用户详情页面）
    jumpDetails(id) {
      this.$router.push(`/commonObjects/detail/${id}/DETAIL`);
    },
    //获取CCChat小组详细信息
    async queryUserGroupInfo(cpnData) {
      this.groupId = cpnData._id;
      this.ownerId = cpnData.ownerId;
      let groupInfoRes = await queryUserGroupInfo({
        groupId: this.groupId,
      });
      this.groupInfoList = groupInfoRes.data.list[0];
      this.groupName = this.groupInfoList.name;
      this.userId = groupInfoRes.data.userId;
      this.typeForm.editAccessType = this.groupInfoList.groupType;
      this.descriptionForm.editDescription = this.groupInfoList.description;
      this.groupInfoList.image = `${VueCookies.get(
        "domainName"
      )}/chatterGroup.action?m=showGroupImage&id=${
        this.menuSelectStatus._id
      }&binding=${this.$CCDK.CCToken.getToken()}&dandan=${Math.random()}`;
      this.oldImg = this.groupInfoList.image;
    },
    //访问类型取消
    editAccessTypeCancel() {
      this.$refs.popoverType.doClose();
    },
    //访问类型保存
    editAccessTypeSave(typeForm) {
      this.$refs[typeForm].validate(async (valid) => {
        if (valid) {
          await updateUserGroupInfo({
            name: this.groupInfoList.name,
            description: this.groupInfoList.description,
            groupType: this.typeForm.editAccessType,
            ownerId: this.ownerId,
            groupId: this.groupId,
          });
          this.queryUserGroupInfo(this.menuSelectStatus);
          this.$bus.$emit("getGroupList");
          this.$refs.popoverType.doClose();
          this.$message.success(this.$i18n.t("message.modify.success"));
        } else {
          return false;
        }
      });
    },
    //修改备注取消
    editDescriptionCancel() {
      this.$refs.popoverDescription.doClose();
    },
    //修改备注保存
    async editDescriptionSave() {
      await updateUserGroupInfo({
        name: this.groupInfoList.name,
        description: this.descriptionForm.editDescription,
        groupType: this.typeForm.editAccessType,
        ownerId: this.ownerId,
        groupId: this.groupId,
      });
      this.queryUserGroupInfo(this.menuSelectStatus);
      this.$bus.$emit("getGroupList");
      this.$message.success(this.$i18n.t("message.modify.success"));
      this.$refs.popoverDescription.doClose();
    },
    //删除小组弹框打开
    delThisGroup() {
      this.delThisGroupTo = true;
    },
    //确认删除小组--调删除小组的接口 -- 需要再调取小组列表的接口-->传回父组件main.vue
    delThisGroupConfirm() {
      this.$emit("delThisGroupConfirm");
      this.delThisGroupTo = false;
    },
    //取消删除小组
    delThisGroupCancel() {
      this.delThisGroupTo = false;
    },
  },
};
</script>

<style lang="scss" scoped>
p {
  padding: 0;
  margin: 0;
}
.dynamic-message {
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .group_message {
    // width: calc(100% - 80px);
    display: flex;
    position: relative;
    // 小相机
    .xiangji {
      position: absolute;
      left: 42px;
      top: 42px;
      cursor: pointer;
      .xiangjiImg {
        width: 25px;
        height: 25px;
      }
    }
    // 头像
    .message_img {
      margin-right: 16px;
      img {
        width: 67px;
        height: 67px;
      }
    }
    // 小组信息
    .message_info {
      // width: 66%;
      .info_title {
        font-size: 16px;
        font-weight: 400;
        color: #080707;
        width: 252px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .jumpGroupDetailsClass {
        color: #006dcc;
        cursor: pointer;
      }
      .info_edit {
        // width: 100%;
        display: flex;
        font-size: 12px;
        //所有人
        .groupName {
          display: flex;
          align-items: center;
          margin-right: 30px;
          padding-bottom: 1px;
          border-bottom: 1px solid #dddbda;
          div:first-of-type {
            color: #666666;
            padding-right: 18px;
          }
          div:last-of-type {
            color: #080707;
            cursor: pointer;
          }
        }
        //访问类型
        .group_type {
          display: flex;
          align-items: center;
          padding-bottom: 1px;
          border-bottom: 1px solid #dddbda;
          div:first-of-type {
            color: #666666;
            padding-right: 20px;
          }
          div:nth-of-type(2) {
            .suoImg {
              width: 10px;
              height: 12px;
            }
            padding-right: 4px;
            // margin-top: -4px;
          }
          div:nth-of-type(3) {
            color: #006dcc;
            padding-right: 20px;
          }
          div:last-of-type {
            padding-right: 4px;
            .editNameImg {
              &:hover {
                cursor: pointer;
              }
            }
          }
        }
      }
      .info_note {
        display: flex;
        font-size: 12px;
        margin-top: 2px;
        padding-bottom: 1px;
        border-bottom: 1px solid #dddbda;
        div:first-of-type {
          color: #666666;
          padding-right: 20px;
        }
        div {
          vertical-align: bottom !important;
        }
        div:nth-of-type(2) {
          color: #080707;
          width: 328px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          padding-right: 10px;
        }
        div:last-of-type {
          .editNameImg {
            margin-top: 4px;
            &:hover {
              cursor: pointer;
            }
          }
        }
      }
    }
  }
  //删除小组
  .group_delete {
    width: 78px;
    line-height: 28px;
    font-size: 12px;
    color: #006dcc;
    text-align: center;
    border-radius: 3px;
    border: 1px solid #dddbda;
    cursor: pointer;
  }
}
</style>
<style lang="scss">
.el-popover.popo_operation {
  width: 70px;
  min-width: 0;
  padding: 0;
  .name-box {
    padding: 15px;
    min-width: 246px;
    min-height: 150px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .editType,
    .editDescription {
      color: #080707;
    }
    .demo-ruleForm {
      color: #080707;
      font-size: 12px;
      ::v-deep .el-form-item {
        margin-bottom: 0px !important;
        ::v-deep .el-form-item__label {
          width: 68px !important;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        ::v-deep .el-form-item__content {
          margin-left: 68px !important;
          ::v-deep .el-form-item__error {
            padding-top: 0px;
          }
          ::v-deep .el-input {
            width: 96%;

            ::v-deep .el-input__inner {
              height: 34px;
              line-height: 34px;
            }
          }
        }
      }
    }
    .demo-ruleForm:last-of-type {
      ::v-deep .el-form-item {
        ::v-deep .el-form-item__label {
          width: 88px !important;
        }
        ::v-deep .el-form-item__content {
          margin-left: 88px !important;
          ::v-deep .el-form-item__error {
            padding-top: 0px;
          }
          ::v-deep .el-input {
            width: 86%;
            ::v-deep .el-input__inner {
              height: 34px;
              line-height: 34px;
            }
          }
        }
      }
    }
    .butts {
      display: flex;
      justify-content: flex-end;
      .el-button {
        padding: 6px 8px;
        border-radius: 4px;
      }
      ::v-deep .el-button--default {
        // background-color: #fff;
        color: #302c2c;
        // border-color: #dddbda;
      }
      // primary
      ::v-deep .el-button--primary {
        // background-color: #409eff;
        // border-color: #409eff;
        margin-right: 6.5px;
      }
    }
  }
  .descriptionruleForm {
    width: 100%;
  }
  .descriptionruleFormItem {
    width: 100% !important;
    // margin-bottom: 0px !important;
    .el-form-item__content {
      width: 100% !important;
      margin-left: 0px !important;
      margin-top: 18px;
      .el-textarea {
        width: 100% !important;
      }
    }
  }
}
</style>
