var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"dynamic-groupList"},[_c('div',{staticClass:"focusOn",class:_vm.activeIndex === '1' ? 'active' : '',on:{"click":function($event){return _vm.menuSelectEvent('1')}}},[_vm._v(" "+_vm._s(_vm.$t("label.h5.whatifollow"))+" ")]),_c('div',{staticClass:"involving",class:_vm.activeIndex === '2' ? 'active' : '',on:{"click":function($event){return _vm.menuSelectEvent('2')}}},[_vm._v(" "+_vm._s(_vm.$t("vue_label_chatter_involving_me"))+" ")]),_c('div',{staticClass:"collection",class:_vm.activeIndex === '3' ? 'active' : '',on:{"click":function($event){return _vm.menuSelectEvent('3')}}},[_vm._v(" "+_vm._s(_vm.$t("label.h5.bookmarked"))+" ")]),_c('div',{staticClass:"microPost",class:_vm.activeIndex === '4' ? 'active' : '',on:{"click":function($event){return _vm.menuSelectEvent('4')}}},[_vm._v(" "+_vm._s(_vm.$t("chatter.feed.menutype.feed.companycom"))+" ")]),_c('div',{staticClass:"groupPart",class:_vm.activeIndex === '5' ? 'active' : ''},[_c('div',{on:{"click":function($event){_vm.groupSelectShow();
        _vm.menuSelectEvent('5');}}},[_vm._v(" "+_vm._s(_vm.$t("label.chat.micropost.a.group"))+" ")]),(
        _vm.activeIndex != '1' ||
        _vm.activeIndex != '2' ||
        _vm.activeIndex != '3' ||
        _vm.activeIndex != '4'
      )?_c('div',{staticClass:"groupAdd",on:{"click":_vm.addGroupShow}},[_c('svg',{directives:[{name:"show",rawName:"v-show",value:(!_vm.changeBlue),expression:"!changeBlue"}],staticClass:"icon groupAddImg",attrs:{"slot":"reference","aria-hidden":"true"},on:{"mouseover":_vm.overChange},slot:"reference"},[_c('use',{attrs:{"href":"#icon-groupAdd"}})]),_c('svg',{directives:[{name:"show",rawName:"v-show",value:(_vm.changeBlue),expression:"changeBlue"}],staticClass:"icon groupAddImg",attrs:{"slot":"reference","aria-hidden":"true"},on:{"mouseout":_vm.outChange},slot:"reference"},[_c('use',{attrs:{"href":"#icon-blueB"}})])]):_vm._e()]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.groupSelectTo),expression:"groupSelectTo"}],staticClass:"groupSelect"},[_c('div',{staticClass:"groupSelectChange"},[_c('el-select',{on:{"change":_vm.changeValue},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}},_vm._l((_vm.groupOptions),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.label,"value":item.label}})}),1)],1),_vm._l((_vm.groupListRes),function(item,index){return _c('div',{key:index,staticClass:"groupDetailedList",class:typeof _vm.activeIndex === 'object' && _vm.activeArrIndex == index
          ? 'active'
          : '',on:{"click":function($event){return _vm.menuSelectEvent(item, index)}}},[_c('div',[_c('img',{attrs:{"src":(_vm.imgUrlCookie + "/chatterGroup.action?m=showGroupImage&id=" + (item._id) + "&binding=" + _vm.tokenCookie + "&dandan=" + (Math.random())),"alt":""}})]),_c('div',[_c('p',[_vm._v(_vm._s(item.name))])]),_c('div',{class:item.isStatus != _vm.$t('label.referperson')
            ? 'openStatus'
            : 'noOpenStatus',on:{"click":function($event){return _vm.isStatusOpen(item.isStatus, item.name, item._id, index)}}},[_vm._v(" "+_vm._s(item.isStatus)+" ")])])}),(_vm.valG != _vm.$t('Dashboard_View_RecentlyView'))?_c('div',{staticClass:"groupPage"},[_c('el-button',{attrs:{"disabled":_vm.pageNum === 1},on:{"click":function($event){return _vm.previousPage()}}},[_vm._v(_vm._s(_vm.$t("label.page.previous")))]),_c('el-button',{attrs:{"disabled":_vm.pageNum > _vm.totalPage - 1},on:{"click":function($event){return _vm.nextPage()}}},[_vm._v(_vm._s(_vm.$t("label.page.next")))])],1):_vm._e()],2),_c('AddGroup',{attrs:{"username":_vm.username,"addGroupTo":_vm.addGroupTo},on:{"theFind":_vm.theFind,"addGroupCancel":_vm.addGroupCancel,"addGroupConfirm":_vm.addGroupConfirm}}),_c('TheFind',{attrs:{"theFindTo":_vm.theFindTo},on:{"theFindCancle":_vm.theFindCancle,"theFindConfirm":_vm.theFindConfirm}}),_c('UploadPicture',{attrs:{"uploadPictureTo":_vm.uploadPictureTo,"newGroupId":_vm.newGroupId},on:{"uploadPictureCancel":_vm.uploadPictureCancel,"uploadPictureConfirm":_vm.uploadPictureConfirm}}),_c('members-manage',{attrs:{"newGroupId":_vm.newGroupId,"queryUserResData":_vm.queryUserResData,"userListData":_vm.userListData,"createdUserId":_vm.createdUserId,"createdUserIdGroup":_vm.createdUserIdGroup,"membersManageTo":_vm.membersManageTo},on:{"loadMore":_vm.loadMore,"clearKeyWord":_vm.clearKeyWord,"getNewPerson":_vm.getNewPerson,"membersManageCancel":_vm.membersManageCancel,"membersManageConfirm":_vm.membersManageConfirm}}),_c('ThisPeopleState',{attrs:{"titleName":_vm.titleName,"isStatus":_vm.isStatus,"thisPeopleStateTo":_vm.thisPeopleStateTo},on:{"thisPeopleStateCancel":_vm.thisPeopleStateCancel,"thisPeopleStateConfirm":_vm.thisPeopleStateConfirm}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }