
<!--
1.只有所有人可以删除小组
2.所有人和管理员都可以同意加入，取消加入，删除成员
-->
<!--
1.公用小组对所有的人可见,所有的人都可以"申请加入"
2.如果小组的所有人没有同意审批就可以,"取消申请",审批之后即为小组成员,就显示"退出小组"
3.专用小组不显示在小组列表中,专用小组管理员和所有人可直接邀请加入,显示"加入小组"
 -->
<template>
  <div class="colleague-circle">
    <div class="content">
      <!-- 小组列表 -->
      <dynamic-groupList @menuSelectEvent="menuSelectEvent"></dynamic-groupList>
      <div
        class="dynamic"
        :style="{ height: heightBox ? heightBox + 'px' : '' }"
      >
        <!-- 小组详细信息 -->
        <dynamic-message
          ref="dynamicMessageRef"
          v-show="isShowRightProfile"
          @delThisGroupConfirm="delThisGroupConfirm"
          :menuSelectStatus="menuSelectStatus"
          :isStatus="isStatus"
        ></dynamic-message>
        <!-- 加入审批--小组成员 -->
        <dynamic-applyFor
          :isStatus="isStatus"
          v-show="isShowRightProfile"
          :groupInfoList="
            $refs.dynamicMessageRef ? $refs.dynamicMessageRef.groupInfoList : {}
          "
          :userId="
            $refs.dynamicMessageRef ? $refs.dynamicMessageRef.userId : ''
          "
        ></dynamic-applyFor>
        <!-- CCChat -->
        <dynamic-public
          :menuSelectStatus="menuSelectStatus"
          :changeGroupId="changeGroupId"
          :postShowType="postShowType"
          :isStatus="isStatus"
        ></dynamic-public>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * 同事圈
 */
import dynamicGroupList from "@/views/colleagueCircle/components/dynamic-groupList.vue";
import dynamicMessage from "@/views/colleagueCircle/components/dynamic-message.vue";
import dynamicApplyFor from "@/views/colleagueCircle/components/dynamic-applyFor.vue";
import dynamicPublic from "@/views/colleagueCircle/components/dynamic-public.vue";
import {
  deleteUserGroup, //删除小组
} from "./api.js";
export default {
  components: {
    dynamicGroupList,
    dynamicMessage,
    dynamicApplyFor,
    dynamicPublic,
  },
  props: {
    // 右侧最大高度
    heightBox: [Number,String]
  },
  data() {
    return {
      /**
       * 当前选中的菜单（非小组不显示除了 CCChat 之外的）
       * 选中每个 小组时是 对象格式，其他的是字符串格式
       */
      menuSelectStatus: "1",
      postShowType: "", //查询相关类型的帖子
      changeGroupId: "", //选中的小组id
      isStatus: "",
    };
  },
  computed: {
    // 是否显示右侧个人简档部分
    isShowRightProfile() {
      if (this.menuSelectStatus === "1") {
        return false;
      } else if (this.menuSelectStatus === "2") {
        return false;
      } else if (this.menuSelectStatus === "3") {
        return false;
      } else if (this.menuSelectStatus === "4") {
        return false;
      } else if (this.menuSelectStatus === "5") {
        return false;
      }
      //  else if (this.$route.query.category == "showmsg") { //切换小组和帖子不显示小组信息
      //   return false;
      // }
      else {
        return true;
      }
    },
  },
  mounted() {
    this.postShowType = "1";
    // 同事圈
    document.title = this.$setTitle(
      this.$i18n.t("label.version.chatter.cooperation")
    );
  },
  methods: {
    // 当前选中的菜单选中事件，包含选择的小组
    menuSelectEvent(status) {
      if (typeof status == "string") {
        // 1,2,3,4,5
        this.postShowType = status;
        this.isStatus = "";
        this.changeGroupId = "empty";
      } else if (typeof status == "object") {
        //小组类型
        this.postShowType = "6";
        this.isStatus = status.isStatus;
        this.changeGroupId = status._id;
      }
      this.menuSelectStatus = status;
    },
    //确认删除小组
    async delThisGroupConfirm() {
      if (typeof this.menuSelectStatus == "object") {
        // this.menuSelectStatus = this.menuSelectStatus;
      }
      let resDelG = await deleteUserGroup({
        groupId: this.menuSelectStatus._id,
      });
      if (resDelG.result == true) {
        this.$message.success(this.$i18n.t("label_tabpage_delsuccessz"));
        this.$bus.$emit("getGroupList");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.colleague-circle {
  height: 100%;
  .ccchat {
    margin: 30px 0 10px 30px;
  }
  .content {
    height: 100%;
    padding: 10px;
    display: flex;
    // 小组列表
    .dynamic-groupList {
      width: 220px;
      // height: 100%;
      background-color: white;
      border-radius: 3px;
    }

    .dynamic {
      width: calc(100% - 230px);
      // height: 100%;
      // max-height: 84vh;
      overflow: auto;

      border-radius: 3px;
      margin-left: 10px;
      //小组详细信息
      .dynamic-message {
        width: 100%;
        background: white;
        border-radius: 3px;
        margin-bottom: 10px;
      }
      //加入审批--小组成员
      .dynamic-applyFor {
        width: 100%;
        background: white;
        border-radius: 3px;
        margin-bottom: 10px;
      }
      //CCChat
      .dynamic-public {
        width: 100%;
        background: white;
        border-radius: 3px;
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
        padding-bottom: 23px;
        .el-tabs__item {
          color: #080707;
          font-size: 14px;
          font-weight: 400;
          background: #f3f2f2;
        }
        .el-tabs__item.is-active {
          background: white;
        }
      }
    }
  }
}
</style>
