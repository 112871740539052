import { render, staticRenderFns } from "./dynamic-groupList.vue?vue&type=template&id=37b08ec6&scoped=true&"
import script from "./dynamic-groupList.vue?vue&type=script&lang=js&"
export * from "./dynamic-groupList.vue?vue&type=script&lang=js&"
import style0 from "./dynamic-groupList.vue?vue&type=style&index=0&id=37b08ec6&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "37b08ec6",
  null
  
)

export default component.exports