<!-- 加入审批--小组成员 -->
<template>
  <div
    class="dynamic-applyFor"
    v-if="
      isStatus === $t('label_tabpage_exitz') ||
      isStatus === $t('label.referperson')
    "
  >
    <!-- 加入审批 -->
    <div
      class="addApplyFor"
      v-if="
        groupInfoList.loginUserGroupRole === 'manager' &&
        groupInfoList.groupType === 'public'
      "
    >
      <!-- 待审批 -->
      <div class="addTitle">
        {{ $t("label.mobile.quickcreate.default.approvals") }}({{
          privateUserNum
        }})
      </div>
      <div class="content">
        <!-- 加入审批的人员 -->
        <div class="addContent">
          <div class="noArrLength" v-if="privatecApplyList.length < 1">
            <!-- 当前没有需要审批的人员 -->
            {{ $t("vue_label_chatter_current_no_people_approval") }}
          </div>
          <div
            v-else
            class="addContentEvery"
            v-for="(item, index) in privatecApplyList"
            :key="index"
          >
            <!--点加载全部展示全部 -->
            <div class="peopleImg">
              <img
                @click="jumpDetails(item.userid)"
                :src="`${imgUrlCookie}/chatterzone.action?m=showPhoto&userid=${item.userid}&binding=${tokenCookie}`"
                alt
              />
            </div>
            <div class="peopleName" @click="jumpDetails(item.userid)">
              {{ item.userName }}
            </div>
            <div class="operation">
              <!-- 批准 -->
              <el-popover
                placement="bottom"
                width="70"
                trigger="hover"
                :content="$t('label.approve')"
                popper-class="popperClassT"
              >
                <svg
                  class="icon imgDui"
                  v-if="groupInfoList.loginUserGroupRole === 'manager'"
                  @click="agreePeopleJoinIn(item.userName, item.userid)"
                  aria-hidden="true"
                  slot="reference"
                >
                  <use href="#icon-dui"></use>
                </svg>
              </el-popover>
              <!-- 拒绝 -->
              <el-popover
                placement="bottom"
                width="70"
                trigger="hover"
                :content="$t('label.refuse')"
                popper-class="popperClassT"
              >
                <svg
                  class="icon imgDui"
                  v-if="groupInfoList.loginUserGroupRole === 'manager'"
                  @click="refusedPeopleJoinIn(item.userName, item.userid)"
                  aria-hidden="true"
                  slot="reference"
                >
                  <use href="#icon-cuo"></use>
                </svg>
              </el-popover>
            </div>
          </div>
        </div>
        <!-- 加载全部 -->
      </div>
    </div>
    <!-- 小组成员 -->
    <div class="groupPeople">
      <div class="peopleTitle">
        {{ $t("vue_label_chatter_team_member") }}({{ publicUserNum }})
      </div>
      <div class="content">
        <!-- 小组全部的成员 -->
        <div class="peopleContent">
          <div
            class="addPeople"
            @click="addPeopleToGroup"
            v-if="groupInfoList.loginUserGroupRole === 'manager'"
          >
            <svg class="icon addPeopleImg" aria-hidden="true" slot="reference">
              <use href="#icon-addPeople"></use>
            </svg>
          </div>
          <div
            class="peopleContentEvery"
            v-for="(item, index) in publicUserList"
            :key="index"
          >
            <!-- 点加载全部展示全部 -->
            <div
              class="peopleImg"
              @mouseover="delPeopleImgShow(index)"
              @mouseout="delPeopleImgShow(index)"
            >
              <img
                class="peopleImgImg"
                @click="jumpDetails(item.userid)"
                :src="`${imgUrlCookie}/chatterzone.action?m=showPhoto&userid=${item.userid}&binding=${tokenCookie}`"
                alt
              />
              <div class="delPeopleImg" v-show="delPeopleImgTo[index]">
                <!-- 角色是管理员可以删除小组人员，但不可删除小组成员中的自己和所有人 -->
                <svg
                  @click="delGroupPeople(index, item.userid, item.userName)"
                  v-if="
                    groupInfoList.loginUserGroupRole === 'manager' &&
                    item.userid !== userId &&
                    item.userid !== groupInfoList.ownerId
                  "
                  class="icon delPeopleImgImg"
                  aria-hidden="true"
                >
                  <use href="#icon-dele"></use>
                </svg>
              </div>
            </div>
            <div class="peopleName" @click="jumpDetails(item.userid)">
              {{ item.userName }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 删除小组成员弹框 -->
    <DelGroupPeople
      @delGroupPeopleConfirm="delGroupPeopleConfirm"
      @delGroupPeopleCancel="delGroupPeopleCancel"
      :delGroupPeopleTo="delGroupPeopleTo"
      :name="groupPersonName"
    />
    <!-- 同意加入弹框 -->
    <AgreeJoinIn
      :agreeJoinInTo="agreeJoinInTo"
      @agreeJoinInConfirm="agreeJoinInConfirm"
      @agreeJoinInCancel="agreeJoinInCancel"
      :name="applyName"
    />
    <!-- 拒绝加入弹框 -->
    <RefusedJoinIn
      :refusedJoinInTo="refusedJoinInTo"
      @refusedJoinInConfirm="refusedJoinInConfirm"
      @refusedJoinInCancel="refusedJoinInCancel"
      :name="applyName"
    />
    <!-- 添加小组成员 小组成员管理 -->
    <MembersManage
      :userListData="userListData"
      :queryUserResData="queryUserResData"
      :createdUserId="createdUserId"
      :createdUserIdGroup="createdUserIdGroup"
      :groupId="groupId"
      :membersManageTo="membersManageTo"
      @membersManageCancel="membersManageCancel"
      @membersManageConfirm="membersManageConfirm"
      @getNewPerson="getNewPerson"
      @clearKeyWord="clearKeyWord"
      @loadMore="loadMore"
      :loading="loading"
    />
  </div>
</template>

<script>
import {
  queryGroupNumberAndApply, //获取CCChat小组成员和专用组申请成员
  removeGroupMember, //成功删除小组成员
  joinGroup, //同意审批(normal),拒绝(rejected)
  queryGroupManager, //查询CCChat小组成员
  queryUser, //查询CCChat小组用户详细信息
} from "../api.js";
import DelGroupPeople from "./dynamic-applyFor/DelGroupPeople";
import AgreeJoinIn from "./dynamic-applyFor/AgreeJoinIn";
import RefusedJoinIn from "./dynamic-applyFor/RefusedJoinIn";
import MembersManage from "./dynamic-applyFor/MembersManage";
import VueCookies from "vue-cookies";
export default {
  props: {
    groupInfoList: {
      type: Object,
      default: () => {},
    },
    userId: {
      // 当前登录用户id
      type: String,
      default: "",
    },
    isStatus: {
      type: String,
      default: "",
    },
  },
  components: {
    DelGroupPeople,
    AgreeJoinIn,
    RefusedJoinIn,
    MembersManage,
  },
  data() {
    return {
      keyword: "",
      delPeopleImgTo: [false],
      delGroupPeopleTo: false,
      agreeJoinInTo: false,
      refusedJoinInTo: false,
      membersManageTo: false,
      tokenCookie: "", //token
      imgUrlCookie: "", //域名
      publicUserNum: "0", //小组成员总数
      publicUserList: [], //小组成员
      publicUserPage: "", //小组成员页数
      privateUserNum: "0", //小组审批总数
      privatecApplyList: [], //审批成员
      groupId: "", //小组ID
      groupPersonId: "", //小组成员ID
      groupPersonName: "", //小组成员name
      eDataGroupId: {},
      applyName: "", //审批成员名字
      applyId: "", //审批成员ID
      pageUserNum: "1",
      pageUserSize: "10",
      userListData: [],
      queryUserResData: [],
      createdUserId: "", //当前登录用户ID -- 所有人员
      createdUserIdGroup: "", //当前登录用户ID -- 小组中
      loading: false,
    };
  },
  mounted() {
    this.$bus.$on("getGroupUser", this.ebFn1);
    this.$bus.$on("getMembersOrUsers", this.ebFn2);
  },
  destroyed() {
    this.$bus.$off("getGroupUser", this.ebFn1);
    this.$bus.$off("getMembersOrUsers", this.ebFn2);
  },
  methods: {
    ebFn1(e) {
      this.eDataGroupId = e;
      //$bus传过来的数据记得在data里重新定义一下
      this.getGroupUsersOrApplyUser();
    },
    ebFn2() {
      this.addPeopleToGroup();
    },
    //点击所有审批成员和小组成员的头像或姓名跳转到对应的详情（用户详情页面）
    jumpDetails(id) {
      this.$router.push(`/commonObjects/detail/${id}/DETAIL`);
    },
    // 获取CCChat小组成员和专用组申请成员
    async getGroupUsersOrApplyUser() {
      if (typeof this.eDataGroupId == "object") {
        this.groupId = this.eDataGroupId._id;
      }
      let publicRes = await queryGroupNumberAndApply({
        groupId: this.groupId,
        mark: "groupNumber",
      });
      this.publicUserNum = publicRes.data.totalSize; //小组成员总数
      this.publicUserList = publicRes.data.userList; //小组成员
      this.publicUserPage = publicRes.data.totalPage; // 小组成员页数,还不知道后端默认每页控制多少人
      // token
      this.tokenCookie = this.$CCDK.CCToken.getToken();
      // 获取域名
      this.imgUrlCookie = VueCookies.get("domainName");
      if (
        this.groupInfoList.groupType === "public" &&
        this.groupInfoList.loginUserGroupRole === "manager"
      ) {
        // 属于公用小组，才会显示审批小组成员
        let privateRes = await queryGroupNumberAndApply({
          groupId: this.groupId,
          mark: "applyNumber",
        });
        this.privateUserNum = privateRes.data.applySize; //小组审批总数
        this.privatecApplyList = privateRes.data.applyUserList; //审批成员
      }
    },
    //搜索
    getNewPerson(newPersonKeyword) {
      this.keyword = newPersonKeyword;
      this.addPeopleToGroup();
    },
    //清空关键字
    clearKeyWord() {
      this.keyword = "";
    },
    //添加小组成员弹框打开
    async addPeopleToGroup() {
      this.loading = true;
      this.membersManageTo = true;
      //查询CCChat小组用户详细信息
      let queryUserRes = await queryUser({
        groupId: this.groupId,
        keyword: this.keyword,
        pageNum: this.pageUserNum,
        pageSize: this.pageUserSize,
      });
      this.queryUserResData = queryUserRes.data.user_l;
      this.createdUserId = queryUserRes.data.userId;
      //查询CCChat小组成员
      let queryGroupManagerRes = await queryGroupManager({
        groupId: this.groupId,
        mark: "all",
        keyword: this.keyword,
        pageNum: this.pageUserNum,
        pageSize: this.pageUserSize,
      });
      this.userListData = queryGroupManagerRes.data.userList;
      this.createdUserIdGroup = queryGroupManagerRes.data.userId;
      this.loading = false;
    },
    //滚动加载
    loadMore() {
      this.pageUserSize = Number(this.pageUserSize) + 5;
      this.addPeopleToGroup();
    },
    //取消添加小组成员
    membersManageCancel() {
      this.membersManageTo = false;
    },
    //确认添加小组成员
    membersManageConfirm() {
      this.membersManageTo = false;
      this.getGroupUsersOrApplyUser();
    },
    //删除小图标的显示和隐藏
    delPeopleImgShow(index) {
      this.delPeopleImgTo[index] = !this.delPeopleImgTo[index];
      this.$forceUpdate();
    },
    //删除小组成员弹框打开
    delGroupPeople(index, id, name) {
      this.delGroupPeopleTo = true;
      this.groupPersonId = id;
      this.groupPersonName = name;
    },
    //成功删除小组成员
    async delGroupPeopleConfirm() {
      this.delGroupPeopleTo = false;
      await removeGroupMember({
        groupId: this.groupId,
        userId: this.groupPersonId,
      });
      this.$message.success(this.$i18n.t("label_tabpage_delsuccessz")); //删除成功
      this.getGroupUsersOrApplyUser();
    },
    //取消删除小组成员
    delGroupPeopleCancel() {
      this.delGroupPeopleTo = false;
    },
    //同意加入弹框打开
    agreePeopleJoinIn(applyName, applyId) {
      this.agreeJoinInTo = true;
      this.applyName = applyName;
      this.applyId = applyId;
    },
    //确定同意加入
    async agreeJoinInConfirm() {
      this.agreeJoinInTo = false;
      await joinGroup({
        groupId: this.groupId,
        userId: this.applyId,
        joinType: "normal",
      });
      this.$message.success(
        this.$i18n.t("vue_label_chatter_approve_members_join")
      ); //"批准成员加入小组"
      this.$bus.$emit("getGroupList");
      this.getGroupUsersOrApplyUser();
    },
    //取消同意加入
    agreeJoinInCancel() {
      this.agreeJoinInTo = false;
    },
    // 拒绝加入弹框打开
    refusedPeopleJoinIn(applyName, applyId) {
      this.refusedJoinInTo = true;
      this.applyName = applyName;
      this.applyId = applyId;
    },
    // 确定拒绝加入
    async refusedJoinInConfirm() {
      this.refusedJoinInTo = false;
       await joinGroup({
        groupId: this.groupId,
        userId: this.applyId,
        joinType: "rejected",
      });
      this.$message.success(
        this.$i18n.t("vue_label_chatter_decline_members_join")
      ); //"拒绝成员加入小组"
      this.$bus.$emit("getGroupList");
      this.getGroupUsersOrApplyUser();
    },
    // 取消拒绝加入
    refusedJoinInCancel() {
      this.refusedJoinInTo = false;
    },
  },
};
</script>
<style lang="scss">
.el-popover.popperClassT {
  z-index: 999;
  padding: 4px 4px;
  font-size: 12px;
  text-align: center;
}
</style>
<style lang="scss" scoped>
p {
  padding: 0;
  margin: 0;
}
.dynamic-applyFor {
  .addApplyFor {
    width: 100%;
    .addTitle {
      padding: 15px 20px;
      font-size: 14px;
      color: #080707;
      background: #e4e4e4;
      border-radius: 3px 3px 0px 0px;
    }
    .content {
      width: 100%;
      display: flex;
      justify-content: space-between;
      padding: 20px 20px 0 20px;
      .addContent {
        .noArrLength {
          padding-bottom: 20px;
        }
        display: flex;
        flex-wrap: wrap;
        max-height: 120px;
        overflow: auto;
        .addContentEvery {
          width: 66px;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          align-items: center;
          margin: 0 26px 20px 0;
          // &:not(:first-child) {} 除了第一个都
          .peopleImg {
            img {
              width: 26px;
              height: 26px;
              border-radius: 50%;
              text-align: center;
              &:hover {
                cursor: pointer;
              }
            }
          }
          .peopleName {
            color: #006dcc;
            font-size: 12px;
            padding-top: 8px;
            &:hover {
              cursor: pointer;
            }
          }
          .operation {
            display: flex;
            justify-content: space-between;
            padding-top: 8px;
            .imgDui {
              width: 18px;
              height: 18px;
              cursor: pointer;
              &:first-of-type {
                margin-right: 10px;
              }
            }
          }
        }
      }
      .addMore {
        position: relative;
        img {
          width: 20px;
          height: 20px;
          position: absolute;
          top: 32px;
          right: 0px;
          cursor: pointer;
        }
      }
    }
  }
  .groupPeople {
    width: 100%;
    .peopleTitle {
      padding: 15px 20px;
      font-size: 14px;
      color: #080707;
      background: #e4e4e4;
      border-radius: 3px 3px 0px 0px;
    }
    .content {
      width: 100%;
      display: flex;
      justify-content: space-between;
      padding: 20px 20px 0 20px;
      .peopleContent {
        display: flex;
        flex-wrap: wrap;
        max-height: 94px;
        overflow: auto;
        .addPeople {
          width: 66px;
          display: table-cell;
          text-align: center;
          vertical-align: middle;
          margin: 4px 20px 20px 0;
          &:hover {
            cursor: pointer;
          }
          .addPeopleImg {
            width: 66px;
            height: 55px;
          }
        }
        .peopleContentEvery {
          width: 66px;
          display: table-cell;
          text-align: center;
          vertical-align: middle;
          margin: 4px 26px 20px 0;
          // &:not(:first-child) {} 除了第一个都
          .peopleImg {
            position: relative;
            .peopleImgImg {
              width: 26px;
              height: 26px;
              border-radius: 50%;
              text-align: center;
              &:hover {
                cursor: pointer;
              }
            }
            .delPeopleImg {
              position: absolute;
              top: -4px;
              left: 44px;
              &:hover {
                cursor: pointer;
              }
              .delPeopleImgImg {
                width: 14px;
                height: 14px;
              }
            }
          }
          .peopleName {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            color: #006dcc;
            font-size: 12px;
            padding-top: 8px;
            &:hover {
              cursor: pointer;
            }
          }
        }
      }
      .addMore {
        position: relative;
        img {
          width: 20px;
          height: 20px;
          position: absolute;
          top: 18px;
          right: 0px;
          cursor: pointer;
        }
      }
    }
  }
}
</style>
