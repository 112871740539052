import { render, staticRenderFns } from "./DelGroupPeople.vue?vue&type=template&id=2a0111ba&scoped=true&"
import script from "./DelGroupPeople.vue?vue&type=script&lang=js&"
export * from "./DelGroupPeople.vue?vue&type=script&lang=js&"
import style0 from "./DelGroupPeople.vue?vue&type=style&index=0&id=2a0111ba&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2a0111ba",
  null
  
)

export default component.exports